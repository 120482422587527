
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import printBulletin from "./components/printBulletin.vue";

interface Subject {
  name: string;
  coef: number;
  mark: number;
  min: string;
  max: string;
}

interface Module {
  name: string;
  coef: number;
  maxMoyenne: string;
  minMoyenne: string;
  subjects: Subject[];
}

interface Bulletin {
  available: boolean;
  moyenne: string;
  maxMoyenne: string;
  minMoyenne: string;
  fullname: string;
  students: number;
  classroomName: string;
  modules: Module[];
  fullName: string;
}

interface Mark {
  mark: number;
  subjectName: string;
  min: string;
  max: string;
}

export default defineComponent({
  name: "Bulletin",
  components: { printBulletin },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();

    const loading = ref(true);

    onMounted(() => {
      setCurrentPageTitle("dashboard.reportPedaAnnuelle");
    });

    const bulletin = ref<Bulletin>({} as Bulletin);

    const studentID = window.localStorage.getItem("activeStudent");

    ApiService.get(`/bulletin/final/parent/${studentID}`)
      .then(({ data }) => {
        bulletin.value = data;
        loading.value = false;
      })
      .catch((e) => {
        console.log(e);
      });

    const isArabic = computed(() => {
      return locale.value == "ar";
    });

    return {
      store,
      t,
      bulletin,
      loading,
      currentStudent: store.getters.currentStudent,
      base_url: store.getters.serverConfigUrl.base_url,
      isArabic,
    };
  },
  methods: {
    capitalize(s: string) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
});
